import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { InformationBox, InfoboxColumn, InfoboxSection, featureList } from './information-box';

export const InfoboxSchoolActivity = (query) => {
  // Drupal only exposes the list item key, not the value, so we duplicate it here and reassign.
  // Must align with the values in the Drupal field_school_years field:
  // /admin/structure/paragraphs_type/information_school_activity/fields/paragraph.information_school_activity.field_school_years/storage
  const remappedSchoolYears = {
    ps_y2: 'Preschool to Year 2',
    y3_6: 'Years 3-6',
    y7_10: 'Years 7-10',
    y11_12: 'Years 11-12',
  };
  const suitableYears = useMemo(
    () =>
      featureList(
        query.field_school_years.map((year) => remappedSchoolYears[year]),
        null,
        null,
        false
      ),
    []
  );

  return (
    <InformationBox>
      {/* Column 1 */}
      <InfoboxColumn>
        {suitableYears && (
          <InfoboxSection sectionTitle="Suitable for">{suitableYears}</InfoboxSection>
        )}
        {query.field_time_required && (
          <InfoboxSection sectionTitle="Duration">
            <p>{query.field_time_required}</p>
          </InfoboxSection>
        )}
      </InfoboxColumn>

      {/* Column 2 */}
      <InfoboxColumn>
        {query.field_price && (
          <InfoboxSection sectionTitle="Price">
            <p>{query.field_price}</p>
          </InfoboxSection>
        )}
        {query.field_max_group_size && (
          <InfoboxSection sectionTitle="Maximum number of students">
            <p>{query.field_max_group_size}</p>
          </InfoboxSection>
        )}
      </InfoboxColumn>
    </InformationBox>
  );
};

export const query = graphql`
  fragment InformationBoxSchoolActivity on paragraph__information_school_activity {
    id
    drupal_id
    internal {
      type
    }
    field_max_group_size
    field_price
    field_school_years
    field_time_required
    paragraph_type {
      drupal_internal__target_id
    }
  }
`;
